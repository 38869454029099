<template>
  <div class="setting-item s-add-more-group">
    <esmp-switch
      v-model="localSetting.value"
      shape="circular"
      :label="localSetting.name"
    />
    <div v-if="localSetting.value" class="setting-item__child-settings">
      <template v-for="item in localSetting.childs">
        <esmp-input
          v-if="item.techName === 'maxNumberOfGroupCopies'"
          class="setting-item__child-setting"
          :key="item.id"
          :label="item.name"
          :value="item.value"
          @input="updateMaxNumberOfGroupCopies(item.id, $event)"
          :options="maxNumberOfGroupCopiesOptions"
        />
        <esmp-checkbox
          v-else
          class="setting-item__child-setting"
          :key="item.id"
          :value="item.value"
          @input="updateChild(item.id, $event)"
        >
          {{ item.name }}
        </esmp-checkbox>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SAddMoreGroup',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxNumberOfGroupCopiesOptions: Object.freeze({
        type: 'number',
        min: 1,
      }),
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    updateMaxNumberOfGroupCopies(id, value) {
      // eslint-disable-next-line no-nested-ternary
      const newValue = !value ? null : value >= 1 ? Number(value) : 1;
      this.updateChild(id, newValue);
    },
    updateChild(id, value) {
      this.localSetting.childs = this.localSetting.childs.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            value,
          };
        }
        return el;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/blocks/control-settings.scss';
</style>
